export const environment = {
  production: true,
  env: 'staging',
  baseUrl: 'https://stage-front-api.vvcr.tv/v1',
  connectionsUrl: 'https://connections.staging.vvcr.tv',
  fileStorageUrl: 'https://storage.vvcr.tv',
  statisticsBaseUrl: 'https://statistics.staging.vvcr.tv',
  storageBaseUrl: 'https://statistics.staging.vvcr.tv',
  trimBaseUrl: 'https://trim-stage.vvcr.live',
  paymentApiV1Url: 'https://signup-api-staging.vvcr.tv/payment_api/v1',
  statisticsWS: 'wss://statistics-sock.dev.vvcr.tv',
  stripe_pk_key: 'pk_test_51IpYnLD34JJwZCeQxLsYelP45INGEytXf9me5hzi5Jzk7ZiWxfkpl3EmO2vHtSBR9k071P8e0fA6P9011FFkJYD9001kd57yo6',
  collaboraAppId: 'dea861f3e0fe4e978314b607a17df421',
  mapBoxToken: 'pk.eyJ1IjoidnZjciIsImEiOiJjbHdqYTdtbDcwemNsMmxvN211YzU2c2F5In0.ukKa8RPgUGCT6AkgMEYOeg'
};
